import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Input,
  Modal,
  InputNumber,
  Select,
  Row,
  Col,
  Upload,
  Switch,
  message,
  Radio,
  Checkbox,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import { getModels } from "../../store/actions/model";
import { getBrands } from "../../store/actions/brand";
import { getSeries } from "../../store/actions/series";
import { addProducts, getProducts, updateProducts } from "../../store/actions/products"; // Import your actions
// import CreatableSelect from 'react-select/creatable';
// import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css";
import { getCountries } from "../../store/actions/country";

const ProductsForm = ({ visible, onCreate, onCancel, record }) => {
  const [form] = Form.useForm();
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedSeries, setSelectedSeries] = useState(null);
  const [imeiFields, setImeiFields] = useState([0]); // Default one IMEI field
  const [imei2Fields, setImei2Fields] = useState([0]); // Default one IMEI2 field
  const [seriesFields, setSeriesFields] = useState([0]); // Default one Series field
  const [mrp, setMrp] = useState(null);
  const [price, setPrice] = useState(null);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [warrantyType, setWarrantyType] = useState(null);
    // Control open state of the Select dropdowns
  const [modelOpen, setModelOpen] = useState(false);
  const [seriesOpen, setSeriesOpen] = useState(false);

  const dispatch = useDispatch();
  const BrandList = useSelector((state) => state.brand.list);
  const ModelList = useSelector((state) => state.model.list);
  const SeriesList = useSelector((state) => state.series.list);
  const countryList = useSelector((state) => state.country.list);

  // References for the model and series select components
  const modelSelectRef = useRef(null);
  const seriesSelectRef = useRef(null);

  // Focus on the model select input when a brand is selected
  useEffect(() => {
    if (selectedBrand && modelSelectRef.current) {
      modelSelectRef.current.focus();
      setModelOpen(true);
    }
  }, [selectedBrand]);

  // Focus on the series select input when a model is selected
  useEffect(() => {
    if (selectedModel && seriesSelectRef.current) {
      seriesSelectRef.current.focus();
      setSeriesOpen(true);
    }
  }, [selectedModel]);

  // Function to calculate discount percentage
  const calculateDiscount = () => {
    if (mrp && price && mrp > 0) {
      const discount = ((mrp - price) / mrp) * 100;
      setDiscountPercentage(discount.toFixed());
      form.setFieldsValue({ disPer: discount.toFixed() });
    } else {
      setDiscountPercentage(0); // Reset if any value is invalid
      form.setFieldsValue({ disPer: 0 });
    }
  };

  useEffect(() => {
    if (selectedBrand) {
      dispatch(getModels(selectedBrand));
      // Clear selected model and series when brand changes
      setSelectedModel(null);
      setSelectedSeries(null);
      form.setFieldsValue({ modelId: undefined, seriesId: undefined });
    }
  }, [selectedBrand, dispatch]);

  useEffect(() => {
    if (selectedModel) {
      dispatch(getSeries(selectedModel));
      setSelectedSeries(null);
      // Clear selected series when model changes
      form.setFieldsValue({ seriesId: undefined });
    }
  }, [selectedModel, dispatch]);

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        // ...record,
        // qty: record.qty || 0,
        qty: 0,
      });
      setImeiFields(
        // Array.from({ length: record.qty || 0 }, (_, index) => index)
        Array.from({ length: 0 }, (_, index) => index)
      );
      setImei2Fields(Array.from({ length: 0 }, (_, index) => index));
      setSeriesFields(Array.from({ length: 0 }, (_, index) => index));
      setSelectedBrand(record.brandId);
      setSelectedModel(record.modelId);
      setSelectedSeries(record.seriesId);
      setMrp(record.mrp);
      setPrice(record.price);
    } else {
      form.resetFields();
      setImeiFields([0]);
      setSelectedBrand(null);
      setSelectedModel(null);
      setSelectedSeries(null);
      setMrp(null);
      setPrice(null);
      setDiscountPercentage(null);
    }
  }, [record, form]);

  // Update name when brand, model, or series changes
  useEffect(() => {
    // const brand = BrandList.find(b => b._id === selectedBrand);
    const model = ModelList.find((m) => m._id === selectedModel);
    const series = SeriesList.find((s) => s._id === selectedSeries);

    // const name = `${brand?.name || ''} ${model?.name || ''} ${series?.name || ''}`.trim();
    const name = `${model?.name || ""} ${series?.name || ""} | ${
      series?.color || ""
    } ${series?.ram || ""}GB + ${series?.storage || ""}GB`.trim();
    form.setFieldsValue({ name });
  }, [
    selectedBrand,
    selectedModel,
    selectedSeries,
    BrandList,
    ModelList,
    SeriesList,
    form,
  ]);

  // Handle radio button change
  const onWarrantyTypeChange = (e) => {
    setWarrantyType(e.target.value);
  };
  useEffect(() => {
    // Fetch brands and models on component mount
    dispatch(getBrands());
    dispatch(getModels());
    dispatch(getSeries());
    dispatch(getCountries());
  }, [dispatch]);

  useEffect(() => {
    calculateDiscount();
  }, [mrp, price]);

  const handleBrandChange = (value) => {
    setSelectedBrand(value);
  };
  const handleModelChange = (value) => {
    const model = ModelList.find((s) => s._id === value);
    if (model) {
      form.setFieldsValue({
        category: model.category,
      });
    }
    setSelectedModel(value);
  };

  // const handleSeriesChange = (value) => {
  //   setSelectedSeries(value); // Update selected series
  // };
  const handleSeriesChange = (value) => {
    const series = SeriesList.find((s) => s._id === value); 
    if (series) {
      form.setFieldsValue({
        ram: series.ram,
        storage: series.storage,
        color: series.color,
        // images: series.images && series.images.length > 0 ? [{
        //   uid: '-1', // Unique identifier for the uploaded image
        //   name: series.images[0].fileName, // The image filename
        //   status: 'done', // Status of the upload
        //   url: series.images[0].imageUrl // Image URL to display
        // }] : []
      });
    }
    setSelectedSeries(value);
  };

  // Filter models based on selected brand
  const filteredModels = ModelList.filter(
    (model) => model.brandId === selectedBrand
  );

  const filteredSeries = SeriesList.filter(
    (series) => series.modelId === selectedModel
  );

  const handleQtyChange = (value) => {
    setImeiFields(Array.from({ length: value }, (_, index) => index));
    setImei2Fields(Array.from({ length: value }, (_, index) => index));
    setSeriesFields(Array.from({ length: value }, (_, index) => index));
  };

  const onFinish = (values) => {
    // Check that the values are correct before submitting
    if (warrantyType === 'warrantyDays' && !values.warrantyDays) {
      console.log('Warranty days are required');
    }
    if (warrantyType === 'warrantyMonthly' && !values.warrantyMonthly) {
      console.log('Warranty months are required');
    }
    console.log(values);
  };
  

  const props = {
    beforeUpload: (file) => {
      const isPNG = file.type === "image/png";
      const isJPG = file.type === "image/jpeg";
      if (!isPNG && !isJPG) {
        // message.error(`${file.name} is not a png/jpeg file`);
        return false; // Reject the upload if not PNG/JPEG
      }
      return true; // Allow the upload if it is PNG/JPEG
    },

    onChange: (info) => {
      // Filter out any invalid files
      let fileList = info.fileList.filter((file) => {
        const isPNG = file.type === "image/png";
        const isJPG = file.type === "image/jpeg";
        if (!isPNG && !isJPG) {
          // Show error message for invalid file type
          message.error(`${file.name} is not a valid image (PNG/JPEG)`);
        }
        return isPNG || isJPG;
      });
      // Update the file list to only include valid images
      form.setFieldsValue({ images: fileList });
    },
  };

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const qty = values.qty || 1;
        const imeiArray = imeiFields.map((_, index) =>
          form.getFieldValue(`imei[${index}]`)
        );

        // Ensure that the number of IMEI fields matches the quantity
        if (imeiArray.length < qty) {
          return Promise.reject(
            new Error("Please provide enough IMEI numbers for the quantity.")
          );
        }

        const apiCalls = [];
        for (let i = 0; i < qty; i++) {
          const formData = new FormData();
          Object.keys(values).forEach((key) => {
            if (key !== "imei" && key !== "images") {
              // Exclude images here
              formData.append(key, values[key]);
              formData.append("qty", 1);
            }
          });

          // Append images
          const images = form.getFieldValue("images");
          if (images && Array.isArray(images)) {
            images.forEach((file) => {
              if (file.originFileObj) {
                formData.append("images", file.originFileObj);
              }
            });
          }

          formData.append("imei", imeiArray[i]);

          if (record) {
            // Update existing product
            formData.append("id", record._id); // Append the product ID for updating
            apiCalls.push(dispatch(updateProducts(formData, form, setSelectedBrand, setSelectedModel)));
          } else {
            // Add new product
            apiCalls.push(dispatch(addProducts(form, formData, setSelectedBrand, setSelectedModel)));
          }
        }

        // Execute all API calls
        Promise.all(apiCalls)
          .then(() => {
            // if (allSuccess) {
              onCreate(); // Notify parent component
              // if (value === 1) { form.resetFields();} // Reset the form
              // form.resetFields();
              setImeiFields([0]); // Reset IMEI fields
              setImei2Fields([0]);
              setSeriesFields([0]);
              // setSelectedBrand(null);
              // setSelectedModel(null);
              // }
          })
          .catch((err) => {
            console.error("Error submitting form:", err);
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <Modal
      visible={visible}
      title={"Add Product"}
      okText="Submit"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={handleFormSubmit}
      width={800}
    >
      <Form
        form={form}
        layout="vertical"
        name="product_form"
        initialValues={{ category: "Mobile" }}
        onFinish={onFinish}
        // initialValues={record}
      >
        {/* Form Fields */}
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="brandId"
              label="Brand"
              rules={[{ required: true, message: "Please select a brand!" }]}
              
            >
              <Select
                placeholder="Select a brand"
                onChange={handleBrandChange}
                value={selectedBrand}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                } 
              >
                {BrandList.map((brand) => (
                  <Select.Option key={brand._id} value={brand._id}>
                    {brand.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
         
          <Col span={8}>
            <Form.Item
              name="modelId"
              label="Series"
              rules={[{ required: true, message: "Please select a series!" }]}
            >
              <Select
                ref={modelSelectRef} // Reference to model select input
                placeholder="Select a series"
                onChange={handleModelChange}
                value={selectedModel}
                disabled={!selectedBrand}
                open={modelOpen} // Control if the dropdown should be open
                onDropdownVisibleChange={(open) => setModelOpen(open)}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                } 
              >
                {filteredModels.map((model) => (
                  <Select.Option key={model._id} value={model._id}>
                    {model.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="category"
              label="Category"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please enter the product category!",
              //   },
              // ]}
            >
              <Select placeholder="Select a Category type" disabled>
                <Select.Option value="Mobile">Mobile</Select.Option>
                <Select.Option value="Tablet">Tablet</Select.Option>
                <Select.Option value="Watch">Watch</Select.Option>
                <Select.Option value="Airpod">Airpod</Select.Option>
                <Select.Option value="Accessories">Accessories</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="seriesId"
              label="Model"
              rules={[{ required: true, message: "Please select a model!" }]}
            >
              <Select
                ref={seriesSelectRef} // Reference to series select input
                placeholder="Select a model"
                value={form.getFieldValue("seriesId")}
                onChange={handleSeriesChange}
                disabled={!selectedModel}
                open={seriesOpen} // Control if the dropdown should be open
                onDropdownVisibleChange={(open) => setSeriesOpen(open)}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {filteredSeries.map((series) => (
                  <Select.Option key={series._id} value={series._id}>
                    {series.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                { required: true, message: "Please enter the product name!" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12} hidden>
            <Form.Item
              name="qty"
              label="Quantity"
              // rules={[
              //   { required: true, message: "Please enter the quantity!" },
              // ]}
            >
              <InputNumber
                min={1}
                style={{ width: "100%" }}
                onChange={handleQtyChange}
                type="number"
                // value={1}
                disabled
                defaultValue={1}
                // defaultValue={record?.qty || 1}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="mrp"
              label="MRP"
              rules={[
                { required: true, message: "Please enter the MRP!" },
                () => ({
                  validator(_, value) {
                    if (value && price && value < price) {
                      return Promise.reject(new Error("MRP should not be less than Price"));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber
                type="number"
                min={0}
                style={{ width: "100%" }}
                value={mrp}
                onChange={setMrp}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="price"
              label="Price"
              rules={[
                { required: true, message: "Please enter the price!" },
                () => ({
                  validator(_, value) {
                    if (value && mrp && value > mrp) {
                      return Promise.reject(new Error("Price should not be greater than MRP"));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber
                type="number"
                min={0}
                style={{ width: "100%" }}
                value={price}
                onChange={setPrice}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="disPer"
              label="Discount Percentage (%)"
              // rules={[
              //   {
              //     required: true,
              //     type: "number",
              //     min: 0,
              //     max: 100,
              //     message: "Discount must be between 0 and 100!",
              //   },
              // ]}
            >
              <InputNumber
                disabled
                type="number"
                min={0}
                max={100}
                style={{ width: "100%" }}
                value={discountPercentage}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          {imeiFields.map((field, index) => (
            // <Row gutter={16} >
            <Col span={8} key={field}>
              <Form.Item
                name={`imei[${index}]`}
                // label={`IMEI 1 ${index + 1}`}
                label={`IMEI 1 ${index === 0 ? "" : index + 1}`}
                rules={[
                  {
                    required: true,
                    message: "Please enter the valid IMEI number!",
                    max: 15
                  },
                ]}
              >
                <Input style={{width:"100%"}} type="number" />
              </Form.Item>
            </Col>
            // </Row>
          ))}
          {/* <Col span={8}>
            <Form.Item
              name={`imei`}
              label={`IMEI 1`}
              rules={[
                {
                  required: true,
                  message: "Please enter the valid IMEI number!",
                  max: 15,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col> */}
          <Col span={8}>
            <Form.Item
              name={`imei2`}
              label={`IMEI 2`}
              // rules={[
              //   {
              //     required: true,
              //     message: "Please enter the valid IMEI 2 number!",
              //     max: 15,
              //   },
              // ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name={`serialNumber`} label={`Serial No.`}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        {/* {imeiFields.map((field, index) => (
          // <Row gutter={16} >
            <Col span={8} key={field}>
              <Form.Item
                name={`imei[${index}]`}
                // label={`IMEI 1 ${index + 1}`}
                label={`IMEI 1 ${index === 0 ? "" : index + 1}`} 
                rules={[
                  {
                    required: true,
                    message: "Please enter the valid IMEI number!",
                    max: 15,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          // </Row>
        ))}
        {imei2Fields.map((field, index) => (
          // <Row gutter={16}>
            <Col span={8}  key={field}>
              <Form.Item
                name={`imei2[${index}]`}
                // label={`IMEI 2 ${index + 1}`}
                label={`IMEI 2 ${index === 0 ? "" : index + 1}`} 
                rules={[
                  {
                    required: true,
                    message: "Please enter the valid IMEI number!",
                    max: 15,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          // </Row>
        ))}
        {seriesFields.map((field, index) => (
          // <Row gutter={16} >
            <Col span={8} key={field}>
              <Form.Item
                name={`imei2[${index}]`}
                label={`serial No. ${index + 1}`}
                rules={[
                  {
                    message: "Please enter the valid IMEI number!",
                    max: 15,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          // </Row>
        ))} */}
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="color"
              label="Color"
              rules={[{ required: true, message: "Please enter the colour!" }]}
            >
              <Input disabled />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="storage"
              label="Storage (GB)"
              rules={[{ required: true, message: "Please enter the storage!" }]}
            >
              <Input type="number" disabled />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="ram"
              label="RAM (GB)"
              rules={[{ required: true, message: "Please enter the ram!" }]}
            >
              <Input type="number" disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={4}>
            <Form.Item
              name="batteryHealth"
              label="Battery(%)"
              rules={[
                {
                  required: true,
                  type: "number",
                  min: 0,
                  max: 100,
                  message: "Please enter the Valid Battery (%)",
                },
              ]}
            >
              <InputNumber  min={0} max={100} type="number" />
            </Form.Item>
          </Col>

          {/* <Col span={8}>
            <Form.Item name="osNo" label="OS">
              <Input />
            </Form.Item>
          </Col> */}

          <Col span={4}>
            <Form.Item
              name="cond"
              label="Condition(%)"
              rules={[
                { required: true,
                  type: "number",
                  min: 0,
                  max: 100, 
                  message: "Please enter valid Condition!" },
              ]}
            >
              <InputNumber type="number"  min={0}
                max={100} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="processor" label="Processor">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="size" label="Size">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="network" label="Network">
              <Select showSearch placeholder="Select a network type">
                <Select.Option value="4G">4G</Select.Option>
                <Select.Option value="5G">5G</Select.Option>
                <Select.Option value="3G">3G</Select.Option>
                <Select.Option value="LTE">LTE</Select.Option>
                {/* Add other network types if needed */}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="countryId"
              label="Country"
              rules={[{ required: true, message: "Please select a country!" }]}
            >
              <Select placeholder="Select a country" showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }>
                {countryList.map((country) => (
                  <Select.Option key={country._id} value={country._id}>
                    {country.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="simVariant" label="SIM">
              <Select placeholder="Select a SIM Variant">
                <Select.Option value="Duel">Duel</Select.Option>
                <Select.Option value="Single">Single</Select.Option>
                <Select.Option value="Duel eSIM">Duel eSIM</Select.Option>
                <Select.Option value="Duel (1 eSIM + 1 Physical)">
                  Duel (1 eSIM + 1 Physical)
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {/* <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="about" label="Description (About Product Details)">
              <ReactQuill
                theme="snow"
                modules={{
                  toolbar: [
                    [{ 'header': [1, 2, false] }],
                    ['bold', 'italic', 'underline'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }]
                  ],
                }}
                style={{ height: '150px' }}
              />
            </Form.Item>
          </Col>
        </Row> */}
        <Row gutter={16} style={{ marginTop: 30 }}>
          <Col span={24}>
            <Form.Item name="goodnote" label="Good Note">
              <Input.TextArea style={{ background: "#caffbf" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="rednote" label="Red Note">
              <Input.TextArea style={{ background: "#ffc4bf" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={4}>
            <Form.Item name="battery" label="Battery in mAh" rules={[
                { 
                  // required: true,
                  type: "number",
                  min: 0,           
                  message: "Please enter valid Battery mAh!" },
              ]}>
              <InputNumber type="number" min={0} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="guarantee" label="Guarantee">
              <Input />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="warranty" label="Warranty">
              <Select showSearch placeholder="Select product Warrenty">
                <Select.Option value="Brand">Brand</Select.Option>
                <Select.Option value="Store">Store</Select.Option>
                <Select.Option value="None">None</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="warrantyType" label="Warranty Type">
              <Radio.Group onChange={onWarrantyTypeChange}>
                <Radio value="warrantyDays">Date</Radio>
                <Radio value="warrantyMonthly">Monthly</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          {warrantyType === "warrantyDays" && (
            <Col span={8}>
              <Form.Item
                name="warrantyDays"
                label="Warranty Date"
              >
                <Input type="date" placeholder="Enter warranty Date" />
              </Form.Item>
            </Col>
          )}
          {warrantyType === "warrantyMonthly" && (
            <Col span={8}>
              <Form.Item
                name="warrantyMonthly"
                label="Monthly Warrenty"
              >
                <Input type="number" placeholder="Enter warranty Months" />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="mmSize" label="mmSize">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="usedType" label="Used Type">
              <Select showSearch placeholder="Select this product Used Type">
                <Select.Option value="USED">USED</Select.Option>
                <Select.Option value="REFURBISHED">REFURBISHED</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="gps"
              label=" "
              valuePropName="checked" // Maps checkbox value to true/false
              getValueFromEvent={(e) => Boolean(e.target.checked)}
              initialValue={false}
            >
              <Checkbox>GPS</Checkbox>
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item
              name="cellular"
              label=" "
              valuePropName="checked" // Maps checkbox value to true/false
              getValueFromEvent={(e) => Boolean(e.target.checked)}
              initialValue={false}
            >
              <Checkbox>Cellular</Checkbox>
            </Form.Item>
          </Col>
          {/* <Col span={12}>
            <Form.Item name="usedTime" label="Used Time">
              <Select placeholder="Select this product Used Time">
                <Select.Option value="upto - 6 Months">upto - 6 Months</Select.Option>
                <Select.Option value="upto 6 Months - 1 Year">upto 6 Months - 1 Year</Select.Option>
                <Select.Option value="upto 1 Year - 2 years">upto 1 Year - 2 years</Select.Option>
                <Select.Option value="upto 2 Years - 3 years">upto 2 Years - 3 years</Select.Option>
                <Select.Option value="Above 3 years">Above 3 years</Select.Option>
              </Select>
            </Form.Item>
          </Col> */}
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="youtubeUrl" label="YouTube URL" 
            rules={[
              {
                required: true,
                message: 'Please input a YouTube URL!',
              },
            ]}>
              <Input defaultValue={""} placeholder="Add Youtube Link" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="images"
              label="Images"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={[
                {
                  required: true,
                  message: "Please upload at least one image!",
                },
              ]}
            >
              <Upload
                {...props}
                listType="picture-card"
                accept="image/png, image/jpeg"
                multiple
              >
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ProductsForm;
