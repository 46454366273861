import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { stringSorter } from "../../utils/functions.js";
import DataTable from "../../components/CustomDatatable/index.js";
import {
  Button,
  Card,
  Space,
  Typography,
  Breadcrumb,
  Row,
  Col,
  Popconfirm,
  Image,
  Switch,
} from "antd";
import {
  EditOutlined,
  HomeOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Spinner } from "reactstrap";
import ModelsSeriesForm from "./ModelsSeriesForm.js";
import { enhanceColumnsWithFilters } from "../../components/ColumnFilters/index.js";
import { addSeries, deleteSeries, getSeries, updateSeries } from "../../store/actions/series.js";
import { getModels } from "../../store/actions/model.js";
import { getBrands } from "../../store/actions/brand.js";
import moment from "moment-timezone";

const { Title } = Typography;

const ModelSeries = () => {
  document.title = "Model Series";
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const dispatch = useDispatch();
  const series = useSelector((state) => state.series.list);
  const modelList = useSelector((state) => state.model.list);
  const brandList = useSelector((state) => state.brand.list);  
  

  const modelMap = modelList.reduce((acc, model) => {
    acc[model._id] = model.name; 
    return acc;
  }, {});

  const brandMap = brandList.reduce((acc, brand) => {
    acc[brand._id] = brand.name;
    return acc;
  }, {});
  

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        await dispatch(getBrands());
        await dispatch(getModels());
        await dispatch(getSeries());
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, [dispatch]);

  const handleCreate = async (values) => {
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('brandId', values.brandId);
    formData.append('modelId', values.modelId);
    formData.append('color', values.color);
    formData.append('ram', values.ram);
    formData.append('storage', values.storage);
    
    if (values.image && values.image.length > 0) {
      formData.append('image', values.image[0].originFileObj);
    }
    if (selectedRecord) {
      formData.append('id', selectedRecord._id);      
      await dispatch(updateSeries({ ...selectedRecord, ...values }));
      await dispatch(getSeries())
    } else {
      await dispatch(addSeries(formData));
      await dispatch(getSeries())
    }
    setIsModalVisible(false);
  };

  const handleDelete = async (id) => {
    await dispatch(deleteSeries(id));
    await dispatch(getSeries());
  };

  const handleChangeStatus = async (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1; // Toggle status
    const updatedData = { _id: id, status: newStatus }; // Create an object for update
    
    await dispatch(updateSeries(updatedData));
    await dispatch(getSeries());
  };

  const renderCell = (text) => (text ? text : 'N/A');
  let columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: stringSorter("name"),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Image",
      dataIndex: "images", // Adjusted to match the correct data key (which is an array of images)
      key: "image",
      render: (images) => {
        if (images && images.length > 0) {
          const imageUrl = images[0].imageUrl; // Assuming the first image is the one you want to display
          return (
            <Image
              src={imageUrl} // Use the imageUrl field
              alt="Series Image"
              preview={false}
              style={{ width: "100px", height: "auto", cursor: "pointer" }}
            />
          );
        }
        return <span>No Image</span>; // Fallback message when no image is available
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={() => {
              setSelectedRecord(record);
              setIsModalVisible(true);
            }}
          >
            <EditOutlined />
          </Button>
          {/* <Popconfirm
            title="Are you sure you want to delete this record?"
            onConfirm={() => handleDelete(record._id)}
          >
            <Button type="link" danger>
              Delete
            </Button>
          </Popconfirm> */}
        </Space>
      ),
    },
    {
      title: "Series",
      dataIndex: "modelId",
      key: "modelId",
      sorter: stringSorter("modelId"),
      sortDirections: ["ascend", "descend"],
      render: (modelId) => modelMap[modelId] || 'Unknown',
    },
    {
      title: "Brand",
      dataIndex: "brandId",
      key: "brandId",
      sorter: stringSorter("brandId"),
      sortDirections: ["ascend", "descend"],
      render: (brandId) => brandMap[brandId] || 'Unknown',
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
      sorter: stringSorter("color"),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Ram (GB)",
      dataIndex: "ram",
      key: "ram",
      sorter: stringSorter("ram"),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Storage (GB)",
      dataIndex: "storage",
      key: "storage",
      sorter: stringSorter("storage"),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => moment(date).isValid() ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "",
      sorter: (a, b) => moment(a.createdAt).isValid() && moment(b.createdAt).isValid() 
        ? moment(a.createdAt).unix() - moment(b.createdAt).unix() 
        : 0,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Created By",
      dataIndex: "createdByName",
      key: "createdByName",
      sorter: stringSorter("createdByName"),
      sortDirections: ["ascend", "descend"],
      render: renderCell,
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (date) => moment(date).isValid() ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "",
      sorter: (a, b) => moment(a.updatedAt).isValid() && moment(b.updatedAt).isValid() 
        ? moment(a.updatedAt).unix() - moment(b.updatedAt).unix() 
        : 0,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Updated By",
      dataIndex: "updatedByName",
      key: "updatedByName",
      sorter: stringSorter("updatedByName"),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <Switch
          checked={text === 1}
          onChange={() => handleChangeStatus(record._id, text)}
        />
      ),
    }
  ];
  columns = enhanceColumnsWithFilters(columns);

  return (
    <div className="page-content">
      <Breadcrumb style={{ marginBottom: "16px" }}>
        <Breadcrumb.Item href="/">
          <HomeOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item>Models</Breadcrumb.Item>
      </Breadcrumb>

      <Card>
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          <Row justify="space-between" align="middle">
            <Col>
              <Title level={2} style={{ margin: 0 }}>
                Manage Models
              </Title>
            </Col>
            <Col>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setSelectedRecord(null);
                  setIsModalVisible(true);
                }}
              >
                Add Series
              </Button>
            </Col>
          </Row>
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <DataTable
              dataSource={series.map((item) => ({
                ...item,
                key: item._id,
              }))}
              columns={columns}
            />
          )}
        </Space>
      </Card>
      <ModelsSeriesForm
        visible={isModalVisible}
        onCreate={handleCreate}
        onCancel={() => setIsModalVisible(false)}
        record={selectedRecord}
      />
    </div>
  );
};

export default ModelSeries;