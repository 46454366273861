import { combineReducers } from "redux";
// Front
import Layout from "./layout/reducer";
// Calendar
import calendar from "./calendar/reducer";
// Authentication
import forgetPassword from "./auth/forgetpwd/reducer";
import login from "./auth/login/reducer";
import profile from "./auth/profile/reducer";
import account from "./auth/register/reducer";

//Items
import item from "./reducers/item";
import salesman from "./reducers/salesman";
import customers from "./reducers/customers";
import products from "./reducers/products";
import brand from "./reducers/brand";
import model from "./reducers/model";
import series from "./reducers/series";
import coupons from "./reducers/coupons";
import notify from "./reducers/notify";
import productorder from "./reducers/productorders";
import country from "./reducers/country";
import state from "./reducers/state";
import city from "./reducers/city";
import settings from "./reducers/settings";
import banner from "./reducers/banner";
import faq from "./reducers/faq";

const rootReducer = combineReducers({
  // public
  Layout,
  calendar,
  forgetPassword,
  login,
  profile,
  account,
  item,
  salesman,
  customers,
  products,
  brand,
  model,
  series,
  coupons,
  notify,
  productorder,
  country,
  state,
  city,
  settings,
  banner,
  faq
});

export default rootReducer;
