import React, { useEffect, useState } from 'react';
import { Form, Input, Modal, Upload, Select, message, Button, Row, Col } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getModels } from '../../store/actions/model';
import { getBrands } from '../../store/actions/brand';

const ModelsSeriesForm = ({ visible, onCreate, onCancel, record }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const BrandList = useSelector((state) => state.brand.list);
  const ModelList = useSelector((state) => state.model.list);
  const ramOptions = [
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '15', label: '15' },
    { value: '16', label: '16' },
    { value: '17', label: '17' },
    { value: '18', label: '18' },
    { value: '19', label: '19' },
    { value: '20', label: '20' },
    { value: '21', label: '21' },
    { value: '22', label: '22' },
    { value: '23', label: '23' },
    { value: '24', label: '24' },
  ];

  const storageOptions = [
    { value: '16', label: '16' },
    { value: '32', label: '32' },
    { value: '64', label: '64' },
    { value: '128', label: '128' },
    { value: '256', label: '256' },
    { value: '512', label: '512' },
    { value: '1024', label: '1024' },
  ];

  const handleCapitalChange = (e) => {
    let value = e.target.value;
    // Capitalize the first letter if there's a value
    if (value && value.length > 0) {
      value = value.charAt(0).toUpperCase() + value.slice(1);
      e.target.value = value; // Update the input with the capitalized value
    }
  };
  const [selectedBrand, setSelectedBrand] = useState(null);

  // File validation for image upload
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    if (e && e.fileList) {
      return e.fileList;
    }
    return [];
  };

  const uploadProps = {
    beforeUpload: (file) => {
      const isPNG = file.type === 'image/png';
      const isJPG = file.type === 'image/jpeg';
      if (!isPNG && !isJPG) {
        message.error(`${file.name} is not a png/jpeg file`);
      }
      return isPNG || isJPG || Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      console.log(info.fileList);
    },
    maxCount: 1,
  };

  // Fetch brand and model data on component mount
  useEffect(() => {
    dispatch(getBrands());
  }, [dispatch]);

  useEffect(() => {
    if (selectedBrand) {
      dispatch(getModels(selectedBrand));
    } else {
      dispatch(getModels()); // Fetch all models if no brand selected
    }
  }, [selectedBrand, dispatch]);

  useEffect(() => {
    if (record) {
      form.setFieldsValue(record);
      setSelectedBrand(record.brandId); // Set selected brand if editing
    } else {
      form.resetFields(); // Reset the form if no record
    }
  }, [record, form]);

  return (
    <Modal
      visible={visible}
      title={record ? 'Edit Model' : 'Add Model'}
      okText={record ? 'Update' : 'Create'}
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => form.validateFields().then((values) => onCreate(values))}
    >
      <Form form={form} layout="vertical" name="modelForm">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="brandId"
              label="Brand"
              rules={[{ required: true, message: 'Please select a brand!' }]}
            >
              <Select
                onChange={(value) => setSelectedBrand(value)}
                placeholder="Select Brand"
              >
                {BrandList.map((brand) => (
                  <Select.Option key={brand._id} value={brand._id}>
                    {brand.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="modelId"
              label="Series"
              rules={[{ required: true, message: 'Please select a series!' }]}
            >
              <Select  placeholder="Select Model">
                {ModelList.filter((model) => model.brandId === selectedBrand).map((model) => (
                  <Select.Option key={model._id} value={model._id}>
                    {model.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name="name"
          label="Model Name"
          rules={[{ required: true, message: 'Please enter model name' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="color"
          label="Color"
          rules={[{ required: true, message: 'Please enter color' }]}
        >
          <Input onChange={handleCapitalChange} />
        </Form.Item>

        <Form.Item
          name="ram"
          label="RAM"
          rules={[{ required: true, message: 'Please select RAM size' }]}
        >
          <Select showSearch placeholder="Select RAM">
            {ramOptions.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="storage"
          label="Storage"
          rules={[{ required: true, message: 'Please select storage size' }]}
        >
          <Select showSearch placeholder="Select Storage">
            {storageOptions.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="image" label="Image" valuePropName="fileList" getValueFromEvent={normFile} extra="Only one image file (PNG/JPG) is allowed.">
          <Upload {...uploadProps} listType="picture">
            <Button icon={<UploadOutlined />}>Upload Image</Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModelsSeriesForm;