import React, { useEffect } from 'react';
import { Form, Input, Modal, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getCountries } from '../../store/actions/country';

const StateForm = ({ visible, onCreate, onCancel, record }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const countryList = useSelector((state) => state.country.list);

  useEffect(() => {
    // Fetch brands on component mount
    dispatch(getCountries());
  }, [dispatch]);

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        name: record.name,
        countryId: record.countryId, 
      });
    }
  }, [record, form]);

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  return (
    <Modal
      visible={visible}
      title={record ? "Update State" : "Add State"}
      okText="Submit"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            onCreate(values);
            form.resetFields();
          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });
      }}
      width={800}
    >
      <Form
        form={form}
        layout="vertical"
        name="model_form"
        initialValues={record}
      >
        <Form.Item
          name="countryId"
          label="Country"
          rules={[{ required: true, message: 'Please select a country!' }]}
        >
          <Select placeholder="Select a country" onChange={handleChange}>
            {countryList.map((country) => (
              <Select.Option key={country._id} value={country._id}>
                {country.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please enter the model name!' }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default StateForm;