import Swal from "sweetalert2";
import { message } from 'antd'; 
import axios from "../../axios";
import {  ADD_SERIES, DELETE_SERIES, GET_SERIES, UPDATE_SERIES } from "../types";
import { setAlert } from "./alert";

export const addSeries = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
          'Content-Type': 'multipart/form-data'
      }
  };
    const res = await axios.post("series/add", formData, config);
    if (res.data.code === 1) {
      dispatch({ type: ADD_SERIES, payload: res.data.data });
      Swal.fire({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
    });
      dispatch(setAlert(res.data.message, "success"));
    } else {
      // dispatch(setAlert(res.data.message, "warning"));
      message.warning(res.data.message);
    }
    message.error(res.data.message);
  } catch (err) {
    // dispatch(setAlert(err.message, "danger"));
    message.error(err.message); 
  }
};
export const getSeries = () => async (dispatch) => {
  try {
    const res = await axios.get("series/get");
    console.log("brand :", res.data);
    if (res.data.code === 1) {
      dispatch({ type: GET_SERIES, payload: res.data.data });
    }
  } catch (err) {
    // dispatch(setAlert(err.message, "danger"));
    message.error(err.message);
  }
};

export const updateSeries = (formData) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };
  try {
    const updatedFormData = new FormData();
    
    // Append non-image fields to FormData
    updatedFormData.append('id', formData._id);
    if (formData.name) updatedFormData.append('name', formData.name);
    if (formData.modelId) updatedFormData.append('modelId', formData.modelId);
    if (formData.color) updatedFormData.append('color', formData.color);
    if (formData.ram) updatedFormData.append('ram', formData.ram);
    if (formData.storage) updatedFormData.append('storage', formData.storage);
    if (formData.status !== undefined) updatedFormData.append('status', formData.status);

    // Log the image data for debugging purposes
    console.log("formData.image:", formData.image);
    console.log("formData.image.length:", formData.image ? formData.image.length : 0);

    // Append image file if present
    if (formData.image && formData.image.length > 0) {
      updatedFormData.append('image', formData.image[0].originFileObj);
    }
    const res = await axios.post("series/update", updatedFormData, config);
    if (res.data.code === 1) {
      dispatch({ type: UPDATE_SERIES, payload: res.data.data });
      Swal.fire({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
    });
      dispatch(setAlert(res.data.message, "success"));
    } else {
      dispatch(setAlert(res.data.message, "warning"));
      Swal.fire({
        title: 'warning!',
        text: res.data.message,
        icon: 'warning',
    });
    }
  } catch (err) {
    console.log("error :", err);
    dispatch(setAlert(err.message, "danger"));
    const errorMessage =
    err.response?.data?.message || "An unexpected error occurred";
    Swal.fire({
      title: "Error!",
      text: errorMessage,
      icon: "error",
    });
    // message.error(err.message);
  }
};

export const deleteSeries = (id) => async (dispatch) => {
  try {
    if (!id) {
      dispatch(setAlert("Please select any record", "warning"));
      return false;
    }
    const res = await axios.post(`series/delete/${id}`);
    if (res.data.code === 1) {
      dispatch({ type: DELETE_SERIES, payload: { id } });
      Swal.fire({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
    });
      dispatch(setAlert(res.data.message, "success"));
    } else {
      // dispatch(setAlert(res.data.message, "warning"));
      message.warning(res.data.message);
    }
  } catch (err) {
    // dispatch(setAlert(err.message, "danger"));
    message.error(err.message);
  }
};
