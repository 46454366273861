import Swal from "sweetalert2";
import axios from "../../axios";
import {
  ADD_BRAND,
  DELETE_BRAND,
  GET_BRANDS,
  UPDATE_BRAND,
} from "../types";
import { setAlert } from "./alert";

export const addBrand = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
          'Content-Type': 'multipart/form-data'
      }
  };
    const res = await axios.post("brand/add", formData, config);
    // console.log("here 1:", res.data.message);
    if (res.data.code === 1) {
      dispatch({ type: ADD_BRAND, payload: res.data.data });
      Swal.fire({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
    });
    dispatch(setAlert(res.data.message, "success"));
    if (res.data.code === 0) {
      // dispatch({ type: ADD_BRAND, payload: res.data.data });
      Swal.fire({
        title: 'Warning!',
        text: res.data.message,
        icon: 'warning',
      });
      // console.log("here :", res.data.message);
      
    }
    } else {
      Swal.fire({
        title: 'Warning!',
        text: res.data.message,
        icon: 'warning',
      });
      dispatch(setAlert(res.data.message, "warning"));
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));    
    Swal.fire({
      title: 'Warning!',
      text: err.response.data.message,
      icon: 'warning',
    });
  }
};
export const getBrands = () => async (dispatch) => {
  try {
    const res = await axios.get("brand/get");
    console.log("brand :", res.data);
    if (res.data.code === 1) {
      dispatch({ type: GET_BRANDS, payload: res.data.data });
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
    const errorMessage = err.response?.data?.message || 'An unexpected error occurred';

      Swal.fire({
          title: 'Error!',
          text: errorMessage,
          icon: 'error',
      });
  }
};

export const updateBrand = (formData) => async (dispatch) => {
  try {
    // console.log('Update Brand - FormData:', formData); // Log the form data

    const updatedFormData = new FormData();
    updatedFormData.append('id', formData._id); // Ensure _id is present
    if (formData.name) updatedFormData.append('name', formData.name);
    if (formData.image && formData.image.length > 0) {
      updatedFormData.append('image', formData.image[0].originFileObj);
    }

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    const res = await axios.post("brand/update", updatedFormData, config);
    console.log('Update response:', res.data); // Log the response
    
    if (res.data.code === 1) {
      dispatch({ type: UPDATE_BRAND, payload: res.data.data });
      Swal.fire({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
      });
      dispatch(setAlert(res.data.message, "success"));
    } else {
      dispatch(setAlert(res.data.message, "warning"));
      Swal.fire({
        title: 'Warning!',
        text: res.data.message,
        icon: 'warning',
      });
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
    Swal.fire({
      title: 'Warning!',
      text: err.response.data.message,
      icon: 'warning',
    });
  }
};

export const updateBrandStatus = (id, status) => async (dispatch) => {
  try {
      // const updatedFormData = { ...formData, id: formData._id };
      // console.log("formdata updateCustomerStatus:", id, status);
      
      const res = await axios.post("brand/update", {id, status}, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
      
      
      if (res.data.code === 1) {
          dispatch({type: UPDATE_BRAND, payload: res.data.data});
          Swal.fire({
              title: 'Success!',
              text: res.data.message,
              icon: 'success',
          });
          dispatch(setAlert(res.data.message, 'success'));
      } else {
          dispatch(setAlert(res.data.message, "warning"));
          Swal.fire({
              title: 'Warning!',
              text: res.data.message,
              icon: 'warning',
          });
      }
  } catch (err) {
      dispatch(setAlert(err.message, 'danger'));
      const errorMessage = err.response?.data?.message || 'An unexpected error occurred';

      Swal.fire({
          title: 'Error!',
          text: errorMessage,
          icon: 'error',
      });
  }
};


// export const updateBrand = (formData) => async (dispatch) => {
//   try {
//     console.log('Updating brand with data:', formData); // Log the data
//     const updatedFormData = new FormData();
//     updatedFormData.append('id', formData._id);
//     if (formData.name) updatedFormData.append('name', formData.name);
//     if (formData.image && formData.image.length > 0) {
//       updatedFormData.append('image', formData.image[0].originFileObj);
//     }

//     const config = {
//       headers: {
//         'Content-Type': 'multipart/form-data'
//       }
//     };

//     const res = await axios.post("brand/update", updatedFormData, config);
//     console.log('Update response:', res.data); // Log the response
    
//     if (res.data.code === 1) {
//       dispatch({ type: UPDATE_BRAND, payload: res.data.data });
//       Swal.fire({
//         title: 'Success!',
//         text: res.data.message,
//         icon: 'success',
//       });
//       dispatch(setAlert(res.data.message, "success"));
//     } else {
//       dispatch(setAlert(res.data.message, "warning"));
//       Swal.fire({
//         title: 'Warning!',
//         text: res.data.message,
//         icon: 'warning',
//       });
//     }
//   } catch (err) {
//     dispatch(setAlert(err.message, "danger"));
//   }
// };


export const deleteBrand = (id) => async (dispatch) => {
  try {
    if (!id) {
      dispatch(setAlert("Please select any record", "warning"));
      return false;
    }
    const res = await axios.post(`brand/delete/${id}`);
    if (res.data.code === 1) {
      dispatch({ type: DELETE_BRAND, payload: { id } });
      Swal.fire({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
    });
      dispatch(setAlert(res.data.message, "success"));
    } else {
      dispatch(setAlert(res.data.message, "warning"));
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};
