import React, { useEffect, useState } from 'react';
import { Form, Input, Modal, Select, Button, Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getCountries } from '../../store/actions/country';
import { getStates } from '../../store/actions/state';
import { getCity } from '../../store/actions/city';

const { Option } = Select;

const StaffFormEdit = ({ visible, onCreate, onCancel, record }) => {
  const [form] = Form.useForm();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const dispatch = useDispatch();
  // const roleMapping = {
  //   2: 'Staff',
  //   3: 'Customer'
  // };

  const StateList = useSelector((state) => state.state.list);
  const countryList = useSelector((state) => state.country.list);
  const cityList = useSelector((state) => state.city.list);

  useEffect(() => {
    if (visible) {
      dispatch(getCountries());
    }
  }, [visible, dispatch]);

  useEffect(() => {
    if (selectedCountry) {
      dispatch(getStates(selectedCountry));
    } else {
      dispatch(getStates());
    }
  }, [selectedCountry, dispatch]);

  useEffect(() => {
    if (selectedState) {
      dispatch(getCity(selectedState));
    } else {
      dispatch(getCity());
    }
  }, [selectedState, dispatch]);

  useEffect(() => {
    if (record) {
      form.setFieldsValue(record);
      setSelectedCountry(record.countryId);
      setSelectedState(record.stateId);
    } else {
      form.resetFields();
      setSelectedCountry(null);
      setSelectedState(null);
    }
  }, [record, form]);

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
    setSelectedState(null); // Reset state selection
    form.setFieldsValue({ stateId: undefined, cityId: undefined }); // Clear dependent fields
  };

  const handleStateChange = (value) => {
    setSelectedState(value);
    form.setFieldsValue({ cityId: undefined }); // Clear city selection
  };

  const filteredStates = StateList.filter(state => state.countryId === selectedCountry);
  const filteredCities = cityList.filter(city => city.stateId === selectedState);

  // Reset form fields when the record changes
  useEffect(() => {
    if (record) {
      form.setFieldsValue(record);
    } else {
      form.resetFields();
    }
  }, [record, form]);

  return (
    <Modal
      visible={visible}
      title={record ? "Update Staff" : "Add Staff"}
      okText="Submit"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            onCreate(values);
            form.resetFields();
          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="staff_form"
        initialValues={record}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: 'Please enter the name!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="mobile"
              label="Mobile"
              rules={[
                { required: true, message: 'Please enter the mobile number!' },
                { pattern: /^[0-9]{10}$/, message: 'Mobile number must be 10 digits!' }
              ]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="email"
              label="Email"
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="countryId"
              label="Country"
              rules={[{ required: true, message: "Please select a country!" }]}
            >
              <Select 
                placeholder="Select a country"
                onChange={handleCountryChange}
              >
                {countryList.map((country) => (
                  <Option key={country._id} value={country._id}>
                    {country.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
          <Form.Item
              name="stateId"
              label="State"
              rules={[{ required: true, message: "Please select a state!" }]}
            >
              <Select 
                placeholder="Select a state"
                onChange={handleStateChange}
              >
                {filteredStates.map((state) => (
                  <Option key={state._id} value={state._id}>
                    {state.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
          <Form.Item
              name="cityId"
              label="City"
              rules={[{ required: true, message: "Please select a city!" }]}
            >
              <Select placeholder="Select a city">
                {filteredCities.map((city) => (
                  <Option key={city._id} value={city._id}>
                    {city.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            {/* <Form.Item
              name="password"
              label="Password"
              rules={[
                { required: true, message: 'Please enter the password!' },
                { min: 8, message: 'Password must be at least 8 characters!' }
              ]}
            >
              <Input.Password />
            </Form.Item> */}
          </Col>
          <Col span={12}>
          <Form.Item
            name="role"
            label="Role"
            rules={[{ required: true, message: "Please select a role!" }]}
          >
            <Select defaultValue={3}>
              <Select.Option value={1}>Admin</Select.Option>
              <Select.Option value={2}>Staff</Select.Option>
            </Select>
          </Form.Item>
          </Col>
          {/* <Col span={12}>
            <Form.Item
              name="role"
              label="Role"
              rules={[{ required: true, message: 'Please select the role!' }]}
            >
              <Select>
                {Object.entries(roleMapping).map(([key, role]) => (
                  <Option key={key}  value={key}>
                    {role}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col> */}
        </Row>
      </Form>
    </Modal>
  );
};

export default StaffFormEdit;



// import React, { useState } from 'react';
// import { Form, Input, Modal, Select, Button } from 'antd';

// const { Option } = Select;

// const StaffForm = ({ visible, onCreate, onCancel, record }) => {
//   const [form] = Form.useForm();
//   const roleMapping = {
//     2: 'Staff',
//     3: 'Customer'
//   };

//   return (
//     <Modal
//       visible={visible}
//       title={record ? "Update Staff" : "Add Staff"}
//       okText="Submit"
//       cancelText="Cancel"
//       onCancel={onCancel}
//       onOk={() => {
//         form
//           .validateFields()
//           .then(values => {
//             onCreate(values);
//             form.resetFields();
//           })
//           .catch(info => {
//             console.log('Validate Failed:', info);
//           });
//       }}
//     >
//       <Form
//         form={form}
//         layout="vertical"
//         name="customer_form"
//         initialValues={record}
//       >
//         <Form.Item
//           name="name"
//           label="Name"
//         >
//           <Input />
//         </Form.Item>
//         <Form.Item
//           name="mobile"
//           label="Mobile"
//         >
//           <Input />
//         </Form.Item>
//         <Form.Item
//           name="email"
//           label="Email"
//         >
//           <Input />
//         </Form.Item>
//         <Form.Item
//           name="city"
//           label="City"
//         >
//           <Input />
//         </Form.Item>
//         <Form.Item
//           name="state"
//           label="State"
//         >
//           <Input />
//         </Form.Item>
//         <Form.Item
//           name="country"
//           label="Country"
//         >
//           <Input />
//         </Form.Item>
//         <Form.Item
//           name="password"
//           label="Password"
//         >
//           <Input type='password' />
//         </Form.Item>
//         <Form.Item
//           name="role"
//           label="Role"
//         >
//            <Select>
//             {Object.entries(roleMapping).map(([key, role]) => (
//               <Option key={key} value={key}>
//                 {role}
//               </Option>
//             ))}
//           </Select>
//         </Form.Item>
//       </Form>
//     </Modal>
//   );
// };

// export default StaffForm;